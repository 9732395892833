import {
  findKey,
  isEmpty,
  isEqual,
  isNil,
  keys,
  omit,
  capitalize,
  isArray,
  sortBy,
  difference,
  filter,
  omitBy,
  has,
  invert,
  forOwn,
  pick,
} from 'lodash';
import {captureError, ShowToast} from '../imports';
import {
  AUTO_MESSAGE_ALLOWED_FIELDS,
  FIELD_TYPE_ID,
  MESSAGE_AUTOMATION,
  MESSAGE_AUTOMATION_REMOVE_KEYS,
  ALLOW_FORMULA_SUBTYPE_AUTOMATION,
  OMIT_ROW_KEYS_FOR_COLIDS,
  CLOUD_FUNCTION_PATHS,
  MINI_APPS,
  COLUMN_PROPERTY_KEYS,
  DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS_V2,
  DATE_FORMATS,
  FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP,
} from './constant';
import {
  checkIfValueExistOnCell,
  getHeaderTypeMapping,
  getLocalText,
  getOriginalColId,
  getOriginalFieldType,
  getTrailText,
  callCloudFunction,
  getColumnFieldType,
} from './utils';
import {
  AUTOMATIONS_APP_BASED_DEFAULT_VALUES,
  AUTOMATION_COLUMN_VALUE_TYPES,
  AUTOMATION_DEFAULT_VALUES_FOR_FIELD_TYPE,
  AUTOMATION_DOC_OPERATIONS,
  AUTOMATION_OPERATION_TYPES,
  COPY_MOVE_DISABLED_IDS,
  DEFAULT_OPERATION_TYPE,
  FIELD_TYPE_TO_OTHER_FIELD_TYPE_CONDITIONS_MAP,
  MAPPING_DEFAULT_VALUES_DETAILS,
  MAPPING_VERSION,
  NON_CHECK_DEFAULT_VALUES,
  NOTIFICATION_OPERATION_USER_TYPES,
  NOTIFICATION_USER_TYPES_OBJECT,
  SET_VALUE_CONDITIONS,
} from './automationConstants';
import {API_TYPES} from './apiConstants';
import {COPY_MOVE_SET_EMPTY_MAP} from './automationMappingConstant';

const ALL_DATE_TIME_FIELDS = [
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.DATE_TIME,
];

const firestoreAutoId = () => {
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let autoId = '';

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

const formatColIdMapFirestore = (colIdMapping) => {
  let updatedColIdMapping = {};
  try {
    for (const placeHolderKey in colIdMapping) {
      updatedColIdMapping = Object.assign({}, updatedColIdMapping, {
        [colIdMapping[placeHolderKey].id]: {
          ...colIdMapping[placeHolderKey],
          placeHolderKey,
        },
      });
    }
  } catch (error) {
    captureError(error);
    return updatedColIdMapping ?? {};
  }
  return updatedColIdMapping ?? {};
};

const getCurrentPhoneColId = (currentAutomation, automationConfig, newAuto) => {
  const id = currentAutomation?.automationId;
  const automationData = automationConfig?.[id] ?? {};
  return newAuto?.phoneColId ?? automationData?.phoneColId;
};

const getAutomationCurrentTemplateId = (stateCurrentAutomation) => {
  let template_id = null;
  if (stateCurrentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
    const colId = findKey(stateCurrentAutomation?.columns);
    if (colId) {
      const triggerStatus = findKey(
        stateCurrentAutomation?.columns?.[colId]?.triggers,
      );
      template_id =
        stateCurrentAutomation?.columns?.[colId]?.triggers?.[triggerStatus]
          ?.notificationTemplateId;
    }
  }
  if (ENTRY_BASED.includes(stateCurrentAutomation?.type)) {
    template_id = stateCurrentAutomation?.notificationTemplateId;
  }
  return template_id;
};

const getAutomationUpdatedTemplateId = (currentAutomation, newAuto) => {
  let template_id = null;
  try {
    if (currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
      template_id = newAuto?.triggerData?.[0]?.notificationTemplateId;
    }
    if (
      [
        ...ENTRY_BASED,
        MESSAGE_AUTOMATION.ROW_EDIT_ENTRY,
        MESSAGE_AUTOMATION.ROW_DELETE_ENTRY,
      ].includes(currentAutomation?.type)
    ) {
      template_id = newAuto?.notificationTemplateId;
    }
  } catch (error) {
    captureError(error);
  }

  return template_id;
};

const getNotificationTemplate = (automationTemplates, id) => {
  return (id && automationTemplates ? automationTemplates : []).find(
    (obj) => obj.templateId === id,
  );
};

const getNotificationTemplateTitle = ({
  stateIsEdit,
  stateCurrentAutomation,
  currentAutomation,
  newAuto,
  automationTemplates,
}) => {
  let title = undefined;
  try {
    if (stateIsEdit) {
      if (ENTRY_BASED.includes(stateCurrentAutomation?.type)) {
        title = getNotificationTemplate(
          automationTemplates,
          getAutomationUpdatedTemplateId(currentAutomation, newAuto) ??
            getAutomationCurrentTemplateId(stateCurrentAutomation),
        )?.title;
      }
      if (
        stateCurrentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
      ) {
        title = getNotificationTemplate(
          automationTemplates,
          getAutomationUpdatedTemplateId(currentAutomation, newAuto) ??
            getAutomationCurrentTemplateId(stateCurrentAutomation),
        )?.title;
      }
    } else {
      if (ENTRY_BASED.includes(currentAutomation?.type)) {
        title = getNotificationTemplate(
          automationTemplates,
          newAuto?.notificationTemplateId,
        )?.title;
      }
      if (currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        title = getNotificationTemplate(
          automationTemplates,
          getTriggerUpdatedNotificationTemplateID(newAuto),
        )?.title;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return title;
};

const getTriggerSelectedStatus = ({
  newAuto,
  getDisplayVal = false,
  currentCol = {},
  userPref = {lang: 'EN'},
}) => {
  let status = null;

  const displayVal = (value) => {
    const selectElements = currentCol?.selectElements;
    const obj = selectElements?.length
      ? selectElements.find?.((item) => item.val == value)
      : -1;
    return obj?.displayName?.[userPref?.lang ?? 'EN'] || obj?.val;
  };

  try {
    const triggerListData = !isEmpty(newAuto?.triggerData)
      ? newAuto?.triggerData
      : [];

    const val =
      triggerListData?.[automationConstants.addToTriggerDataIndex]?.status;

    status = getDisplayVal ? displayVal(val) : val;
  } catch (error) {
    captureError(error);
  }
  return status;
};

const getTriggerCurrentStatus = (stateCurrentAutomation) => {
  let status = null;
  try {
    const colId = findKey(stateCurrentAutomation?.columns);
    if (colId) {
      const thisStatus = findKey(
        stateCurrentAutomation?.columns?.[colId]?.triggers,
      );
      if (thisStatus) {
        status = thisStatus;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return status;
};

const getUpdatedAutomationColIdMapping = (newAuto, currentAutomation) => {
  let colIdMapping = undefined;
  try {
    if (!isEmpty(newAuto) && !isEmpty(currentAutomation)) {
      if (currentAutomation.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
        if (!isEmpty(newAuto?.triggerData)) {
          colIdMapping = newAuto?.triggerData?.[0]?.colIdMapping;
        }
      }
      if (ENTRY_BASED.includes(currentAutomation.type)) {
        colIdMapping = newAuto?.colIdMapping;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return colIdMapping;
};

const getCurrentAutomationColIdMappingUtils = (
  automationId,
  automationConfig,
) => {
  try {
    const automationData = automationConfig?.[automationId];
    const {type, columns, colIdMapping} = automationData || {};
    if (type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE && !isEmpty(columns)) {
      const [colId] = Object.keys(columns);
      const {triggers} = columns[colId] || {};
      if (!isEmpty(triggers)) {
        const [triggerStatus] = Object.keys(triggers);
        return triggers[triggerStatus]?.colIdMapping;
      }
    } else if (ENTRY_BASED.includes(type)) {
      return colIdMapping;
    }
  } catch (error) {
    captureError(error);
  }
  return undefined;
};

const getTriggerUpdatedNotificationTemplateID = (newAuto) => {
  let template_id = null;
  try {
    if (newAuto?.triggerData?.[0]?.notificationTemplateId) {
      template_id = newAuto?.triggerData?.[0]?.notificationTemplateId;
    }
  } catch (error) {
    captureError(error);
  }
  return template_id;
};

const triggerBASED_AUTOMATIONS = [
  MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
  MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE,
];

const ENTRY_BASED = [
  MESSAGE_AUTOMATION.ON_USER_APP_ACCESS,
  MESSAGE_AUTOMATION.MANUAL,
  MESSAGE_AUTOMATION.ROW_NEW_ENTRY,
  MESSAGE_AUTOMATION.ROW_EDIT_ENTRY,
  MESSAGE_AUTOMATION.ROW_DELETE_ENTRY,
  MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
  MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE,
];

const sendNotificationAddCheckFN = (newAuto, type) => {
  const {
    userType,
    usersList,
    userPermissions,
    userCustomPermissions,
    messageConfig,
  } = newAuto;

  if (
    (type === MESSAGE_AUTOMATION.MANUAL ||
      type === MESSAGE_AUTOMATION.ON_USER_APP_ACCESS) &&
    isEmpty(messageConfig)
  ) {
    return false;
  }

  switch (userType) {
    case NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS:
    case NOTIFICATION_OPERATION_USER_TYPES.CREATOR:
      return true;
    case NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE:
      return newAuto?.phoneColId?.val?.length;
    case NOTIFICATION_OPERATION_USER_TYPES.USER_LIST:
      return !isEmpty(usersList);
    case NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION:
      return !isEmpty(userPermissions) || !isEmpty(userCustomPermissions);
    default:
      return false;
  }
};

const compareStringArrays = (prev, current) => {
  if (!current?.length) return false;
  if (!prev?.length && current?.length) return true;

  const prevIds = (prev?.length ? prev : []).sort();
  const currentIds = (current?.length ? current : []).sort();

  return !isEqual(prevIds, currentIds);
};

const sendEmailAddCheck = (newAuto, type) => {
  const {
    userType,
    usersList,
    userColumnIds,
    userPermissions,
    userCustomPermissions,
    messageConfig,
    emailIntegration,
  } = newAuto;

  const commonEmptyCheck = isEmpty(messageConfig) || isEmpty(emailIntegration);
  // if (type === MESSAGE_AUTOMATION.MANUAL && commonEmptyCheck) {
  //   return false;
  // }

  if (commonEmptyCheck) {
    return false;
  }

  switch (userType) {
    case NOTIFICATION_OPERATION_USER_TYPES.ALL_ORGANIZATION_USERS:
    case NOTIFICATION_OPERATION_USER_TYPES.TRIGGERED_BY:
    case NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS:
    case NOTIFICATION_OPERATION_USER_TYPES.CREATOR:
      return true;
    // case NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE:
    //   return newAuto?.phoneColId?.val?.length;
    case NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS:
      return !isEmpty(userColumnIds);
    case NOTIFICATION_OPERATION_USER_TYPES.USER_LIST:
      return !isEmpty(usersList);
    case NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION:
      return !isEmpty(userPermissions) || !isEmpty(userCustomPermissions);
    default:
      return false;
  }
};

const sendEmailEditCheck = (prevAutoConfig, newAuto, extra = {}) => {
  const {
    userType,
    usersList,
    userColumnIds,
    userPermissions,
    userCustomPermissions,

    // phoneColId,
    messageConfig,

    ccUserType,
    ccUserColumnIds,
    ccUsersList,
    ccUserPermissions,
    ccUserCustomPermissions,
  } = newAuto;

  const {
    usersList: usersListPrev,
    userColumnIds: userColumnIdsPrev,
    userPermissions: userPermissionsPrev,
    userCustomPermissions: userCustomPermissionsPrev,
    // phoneColId: phoneColIdPrev,
    messageConfig: messageConfigPrev,

    ccUsersList: ccUsersListPrev,
    ccUserColumnIds: ccUserColumnIdsPrev,
    ccUserPermissions: ccUserPermissionsPrev,
    ccUserCustomPermissions: ccUserCustomPermissionsPrev,
  } = prevAutoConfig;

  const hasChanges = userType !== prevAutoConfig.userType;
  const hasCCChanges = ccUserType !== prevAutoConfig.ccUserType;

  const getTypeChanges = () => {
    switch (userType) {
      case NOTIFICATION_OPERATION_USER_TYPES.ALL_ORGANIZATION_USERS:
      case NOTIFICATION_OPERATION_USER_TYPES.TRIGGERED_BY:
      case NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS:
      case NOTIFICATION_OPERATION_USER_TYPES.CREATOR:
        return hasChanges;
      // case NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE:
      //   return phoneColId?.id && phoneColIdPrev?.id !== phoneColId?.id;
      case NOTIFICATION_OPERATION_USER_TYPES.USER_LIST:
        return compareStringArrays(usersListPrev, usersList);
      case NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS:
        return compareStringArrays(userColumnIdsPrev, userColumnIds);
      case NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION:
        return (
          compareStringArrays(userPermissionsPrev, userPermissions) ||
          compareStringArrays(userCustomPermissionsPrev, userCustomPermissions)
        );
      default:
        return false;
    }
  };

  const getCCTypeChanges = () => {
    switch (ccUserType) {
      case NOTIFICATION_OPERATION_USER_TYPES.ALL_ORGANIZATION_USERS:
      case NOTIFICATION_OPERATION_USER_TYPES.TRIGGERED_BY:
      case NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS:
      case NOTIFICATION_OPERATION_USER_TYPES.CREATOR:
        return hasCCChanges;
      // case NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE:
      //   return phoneColId?.id && phoneColIdPrev?.id !== phoneColId?.id;
      case NOTIFICATION_OPERATION_USER_TYPES.USER_LIST:
        return compareStringArrays(ccUsersListPrev, ccUsersList);
      case NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS:
        return compareStringArrays(ccUserColumnIdsPrev, ccUserColumnIds);
      case NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION:
        return (
          compareStringArrays(ccUserPermissionsPrev, ccUserPermissions) ||
          compareStringArrays(
            ccUserCustomPermissionsPrev,
            ccUserCustomPermissions,
          )
        );
      default:
        return false;
    }
  };

  const isEmailIntegrationChanged = !isEqual(
    prevAutoConfig?.emailIntegration,
    newAuto?.emailIntegration,
  );

  const messageConfigChanges = !isEmpty(messageConfig)
    ? !isEqual(messageConfigPrev, messageConfig)
    : !isEmpty(messageConfigPrev) && isEmpty(messageConfig);
  return (
    isEmailIntegrationChanged ||
    getTypeChanges() ||
    getCCTypeChanges() ||
    messageConfigChanges
  );
};

const sendNotificationChangesCheckFN = (
  prevAutoConfig,
  newAuto,
  extra = {},
) => {
  const {
    userType,
    usersList,
    userPermissions,
    phoneColId,
    userCustomPermissions,
    messageConfig,
  } = newAuto;

  const {
    usersList: userListPrev,
    userPermissions: userPermissionsPrev,
    userCustomPermissions: userCustomPermissionsPrev,
    phoneColId: phoneColIdPrev,
    messageConfig: messageConfigPrev,
  } = prevAutoConfig;

  const hasChanges = userType !== prevAutoConfig.userType;

  const getTypeChanges = () => {
    switch (userType) {
      case NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS:
      case NOTIFICATION_OPERATION_USER_TYPES.CREATOR:
        return hasChanges;
      case NOTIFICATION_OPERATION_USER_TYPES.ASSIGNEE:
        return phoneColId?.id && phoneColIdPrev?.id !== phoneColId?.id;
      case NOTIFICATION_OPERATION_USER_TYPES.USER_LIST:
        return compareStringArrays(userListPrev, usersList);
      case NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION:
        return (
          compareStringArrays(userPermissionsPrev, userPermissions) ||
          compareStringArrays(userCustomPermissionsPrev, userCustomPermissions)
        );
      default:
        return false;
    }
  };

  const messageConfigChanges = !isEmpty(messageConfig)
    ? !isEqual(messageConfigPrev, messageConfig)
    : !isEmpty(messageConfigPrev) && isEmpty(messageConfig);
  return (
    getTypeChanges() || (extra?.fromMiniApps ? messageConfigChanges : false)
  );
};

const sendNotificationCheckFN = (
  prevAutoConfig,
  newAuto,
  addCheck,
  type,
  extra = {},
) => {
  const {userType} = newAuto;
  const isUserTypeChanged = prevAutoConfig?.userType !== userType;
  if (addCheck || isUserTypeChanged) {
    return sendNotificationAddCheckFN(newAuto, type);
  }

  return sendNotificationChangesCheckFN(prevAutoConfig, newAuto, {
    fromMiniApps: extra?.fromMiniApps,
  });
};

const sendEmailCheck = (
  prevAutoConfig,
  newAuto,
  addCheck,
  type,
  extra = {},
) => {
  const {userType} = newAuto;
  const isUserTypeChanged = prevAutoConfig?.userType !== userType;
  if (addCheck || isUserTypeChanged) {
    return sendEmailAddCheck(newAuto, type);
  }

  return sendEmailEditCheck(prevAutoConfig, newAuto, {
    fromMiniApps: extra?.fromMiniApps,
  });
};

const isDifferentFields = (currentObj, previousObj, path, defaultVal) => {
  const currentVal = currentObj?.[path] ?? defaultVal;
  const previousVal = previousObj?.[path] ?? defaultVal;

  return !isEqual(currentVal, previousVal);
};

const operationTypeChecksFN = (
  operationTypeData,
  extra = {initCheck: false, fromMiniApps: false},
) => {
  const {initCheck, fromMiniApps} = extra;
  const {
    isOperationType,
    setValueFields, // SET_VALUE_BY_FILTERS - Local State

    automationDocumentId,
    operationType,
    extraDocOperationTypes,
    newAuto,
    prevAutoConfig,
  } = operationTypeData;

  // New Values to Compare
  const idPasteTo = newAuto?.idPasteTo?.length ? newAuto?.idPasteTo : null;

  const mappingFields = getAutomationCopyMoveSetMappingFields(newAuto, null);
  const additionalMappingFields = Object.keys(
    newAuto?.additionalDataSource ?? {},
  ).reduce((acc, docId) => {
    const docMappingFields = pick(
      newAuto?.additionalDataSource[docId] ?? {},
      Object.keys(COPY_MOVE_SET_EMPTY_MAP),
    );

    Object.keys(COPY_MOVE_SET_EMPTY_MAP).forEach((key) => {
      acc[key] = {...(acc[key] ?? {}), ...(docMappingFields?.[key] ?? {})};
    });

    return acc;
  }, {});

  const filterColToColMap = newAuto?.filterColToColMap ?? null; // SET_VALUE_BY_FILTERS

  // ! Previous Values to Compare (Handled with new `isDifferentFields` function)
  // const prevIdPasteTo = prevAutoConfig?.idPasteTo?.length ? prevAutoConfig?.idPasteTo : null;

  const {SET_VALUE_BY_FILTERS} = AUTOMATION_OPERATION_TYPES;
  const isSetValue = operationType === SET_VALUE_BY_FILTERS;
  const sameDocument = automationDocumentId === idPasteTo;
  const fieldIds = (setValueFields || []).map((item) => item?.id);

  const allMappedFields = Object.keys({
    ...mappingFields?.headerMapping,
    ...mappingFields?.defaultValMap,
    ...mappingFields?.defaultValConstantMap,
    ...(mappingFields?.aggregationColMap ?? {}),
    ...(mappingFields?.endpointColMap ?? {}),

    // For Additional Sources
    ...additionalMappingFields?.headerMapping,
    ...additionalMappingFields?.defaultValConstantMap,
  });

  const hasAllFields = difference(fieldIds, allMappedFields).length === 0;

  const filterCondition = !sameDocument
    ? !isEmpty(filterColToColMap) &&
      checkAllSetValueConditionsFilled(filterColToColMap)
    : true;
  const hasSetValueData = hasAllFields ? filterCondition : false;

  if (initCheck) {
    if (operationType === AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW) {
      return Boolean(idPasteTo);
    }
    // ! For Non MiniApp Automation flow - `extraDocOperationTypes` will be empty and we will need `headerMapping` for that
    // prettier-ignore
    const anyFieldMapped = !extraDocOperationTypes?.length
      ? !isEmpty(mappingFields?.headerMapping)
      : checkIfAutomationCopyMoveSetMappingFieldsMapped(newAuto)

    return Boolean(
      anyFieldMapped && idPasteTo && (isSetValue ? hasSetValueData : true),
    );
  }

  function checkDifferences() {
    const COPY_MOVE_SET_EMPTY_MAP_KEYS = Object.keys(COPY_MOVE_SET_EMPTY_MAP);
    const fieldsToCheck = [
      {field: 'idPasteTo', defaultValue: null},
      {field: 'filterColToColMap', defaultValue: {}},
      {field: 'filterConditionType', defaultValue: null},
      {field: 'toDocOperation', defaultValue: undefined},
      {field: 'updateOnUniqueFail', defaultValue: undefined},
      {field: 'additionalDataSource', defaultValue: undefined},
      ...COPY_MOVE_SET_EMPTY_MAP_KEYS.map((field) => ({
        field,
        defaultValue: {},
      })),
    ];

    const currentDoc = fieldsToCheck.some(({field, defaultValue}) =>
      isDifferentFields(newAuto, prevAutoConfig, field, defaultValue),
    );

    const additionalSources = !isEmpty(newAuto?.additionalDataSource)
      ? fieldsToCheck.some(({field, defaultValue}) => {
          return Object.keys(newAuto.additionalDataSource).some((docId) => {
            return isDifferentFields(
              newAuto.additionalDataSource[docId],
              prevAutoConfig?.additionalDataSource?.[docId] ?? {},
              field,
              defaultValue,
            );
          });
        })
      : false;

    return currentDoc || additionalSources;
  }

  return isOperationType &&
    (!fromMiniApps ? mappingFields?.headerMapping : true) &&
    idPasteTo &&
    (isSetValue ? hasSetValueData : true)
    ? checkDifferences()
    : false;
};

const getUpdatedNotificationId = (type, newAuto) => {
  if (type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
    return getTriggerUpdatedNotificationTemplateID(newAuto);
  }
  return newAuto?.notificationTemplateId;
};

const checkAllSetValueConditionsFilled = (conditions) => {
  if (!conditions || !conditions.length) return false;

  const {HIDE_VALUE_FIELD_FOR_CONDITIONS} = MINI_APPS.AUTOMATION;

  return conditions.every((condition) => {
    const valueCONDITION = condition?.[SET_VALUE_CONDITIONS.CONDITION]?.value; // Condition ID
    const valueVALUE = condition?.[SET_VALUE_CONDITIONS.VALUE]?.value;

    if (HIDE_VALUE_FIELD_FOR_CONDITIONS.includes(valueCONDITION)) {
      return true;
    }

    return Boolean(valueVALUE);
  });
};

const isAllDataAddedForAddEditButton = (
  type,
  reduxConstTemplateValues,
  newAuto,
  currentCol,
  operationTypeData,
  isUserDefined,
  currentAutomation,
  extra = {},
) => {
  if (!type) {
    return false;
  }
  const {operationType} = currentAutomation ?? {};
  const updatedPhoneColId = getOriginalColId(newAuto?.phoneColId?.id);
  const updatedColIdMapping = getUpdatedAutomationColIdMapping(
    newAuto,
    currentAutomation,
  );
  const updatedNotificationId = getUpdatedNotificationId(type, newAuto);
  // prettier-ignore
  const messageBasedAllData = updatedNotificationId && (updatedColIdMapping || reduxConstTemplateValues);
  const {userDefined} = newAuto ?? {};
  // prettier-ignore
  const withUserDefinedCheck = isUserDefined ? !isEmpty(userDefined) : messageBasedAllData;
  // prettier-ignore
  const commonMessageCheck = withUserDefinedCheck && (type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE ? true : updatedPhoneColId);

  // ! For case ROW_NEW_ENTRY:
  // ! For case ON_SPECIFIC_DATE:
  // ! For case ON_ASSIGNEE_CHANGE:
  const updatedTriggerCol = newAuto?.triggerCol?.id;
  const triggerCheck = triggerBASED_AUTOMATIONS.includes(type)
    ? updatedTriggerCol
    : true;

  if (ENTRY_BASED.includes(type) && !triggerCheck) return false;

  // ! For case CELL_STATUS_CHANGE:
  const isCELL_STATUS_CHANGE = type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE;
  const updatedDropDownCol = getOriginalColId(currentCol?.id);
  const updatedStatus = getTriggerSelectedStatus({newAuto});
  const cellStatusCheck = updatedStatus && updatedDropDownCol;

  if (isCELL_STATUS_CHANGE && !cellStatusCheck) return false;

  // ! For case COPY_ROW:
  // ! For case MOVE_ROW:
  const {isOperationType} = operationTypeData;

  if (isOperationType)
    return operationTypeChecksFN(operationTypeData, {
      initCheck: true,
      fromMiniApps: extra?.fromMiniApps,
    });

  // ! For SEND NOTIFICATION
  const {SEND_NOTIFICATION, SEND_EMAIL} = AUTOMATION_OPERATION_TYPES;
  const isSendNotification = operationType === SEND_NOTIFICATION;
  const isSendEmail = operationType === SEND_EMAIL;

  if (isSendNotification)
    return sendNotificationCheckFN({}, newAuto, true, type);

  if (isSendEmail) return sendEmailCheck({}, newAuto, true, type);

  return commonMessageCheck;
};

const specificAutomationCheckFN = (
  newAuto,
  prevAutoConfig,
  notifChangeChecks,
  stateType,
) => {
  const currentTriggerColId = newAuto?.triggerCol?.id;
  const prevTriggerColId = prevAutoConfig.triggerCol?.id ?? null;
  //prettier-ignore
  const triggerColIdChanged = !isNil(prevTriggerColId) && !isNil(currentTriggerColId) && !isEqual(prevTriggerColId, currentTriggerColId);

  //prettier-ignore
  return triggerBASED_AUTOMATIONS.includes(stateType) ? triggerColIdChanged || notifChangeChecks : false;
};

const cellStatusChangesCheckFN = (
  stateCurrentAutomation,
  newAuto,
  triggerCurrentcolId,
  currentCol,
) => {
  const currentStatus = getTriggerCurrentStatus(stateCurrentAutomation);
  const updatedStatus = getTriggerSelectedStatus({newAuto});
  const currentColId = getOriginalColId(triggerCurrentcolId);
  const updatedColId = getOriginalColId(currentCol?.id);

  //prettier-ignore
  const statusValChanged = (currentColId && currentStatus && updatedStatus && updatedColId == currentColId && currentStatus != updatedStatus);
  //prettier-ignore
  const statusColChanged = (updatedColId && updatedStatus && updatedColId != currentColId);

  return statusColChanged || statusValChanged;
};

const checkIfUserDefinedDataAdded = (
  isUserDefined,
  stateCurrentAutomation,
  newAuto,
) => {
  const previousUserDefinedData = stateCurrentAutomation?.userDefined;
  const updatedUserDefinedData = newAuto?.userDefined;
  return (
    isUserDefined &&
    !isEmpty(updatedUserDefinedData) &&
    !isEmpty(previousUserDefinedData) &&
    !isEqual(updatedUserDefinedData, previousUserDefinedData)
  );
};

const checkAllTriggerConditionsChanges = (
  newAuto,
  currentAutomation,
  stateCurrentAutomation,
) => {
  const {
    allTriggerConditions: stateAllTriggerConditions,
    allTriggerConditionType: stateAllTriggerConditionType,
  } = stateCurrentAutomation;
  const {TRIGGER_TYPE_PROPERTIES, TRIGGER_CONFIGS} = MINI_APPS.AUTOMATION;

  const shouldCheckForCommonCustomConditions = Boolean(
    TRIGGER_TYPE_PROPERTIES?.[currentAutomation?.type]?.CONFIG_FIELDS?.find(
      (item) => item?.id === TRIGGER_CONFIGS.SET_CUSTOM_CONDITIONS_TRIGGER,
    ),
  );

  const allTriggerConditionsChanged =
    !isEmpty(newAuto?.allTriggerConditions) ||
    !isEmpty(stateAllTriggerConditions)
      ? !isEqual(newAuto?.allTriggerConditions, stateAllTriggerConditions)
      : false;

  const allTriggerConditionTypeChanged =
    newAuto?.allTriggerConditionType?.length ||
    stateAllTriggerConditionType?.length
      ? newAuto?.allTriggerConditionType !== stateAllTriggerConditionType
      : false;

  return shouldCheckForCommonCustomConditions
    ? allTriggerConditionsChanged || allTriggerConditionTypeChanged
    : false;
};

const checkEntryModifiedConfigChanges = (prevAutoConfig, newAuto) => {
  const {EDIT_TRIGGER_TYPES} =
    MINI_APPS.AUTOMATION.TRIGGER_TYPE_PROPERTIES[
      MINI_APPS.AUTOMATION.TRIGGER_TYPES.ROW_EDIT_ENTRY
    ];

  const editTriggerOnEmptySetChanged = !isEqual(
    Boolean(prevAutoConfig?.editTriggerOnEmptySet),
    Boolean(newAuto?.editTriggerOnEmptySet),
  );

  const editTriggerTypeChanged = !isEqual(
    prevAutoConfig?.editTriggerType,
    newAuto?.editTriggerType,
  );

  const editTriggerColsChanged = [
    EDIT_TRIGGER_TYPES.ANY_ONE_OF_SELECTED,
    EDIT_TRIGGER_TYPES.ALL_SELECTED,
  ].includes(newAuto?.editTriggerType)
    ? !isEmpty(newAuto?.editTriggerCols) &&
      !isEqual(prevAutoConfig?.editTriggerCols, newAuto?.editTriggerCols)
    : false;

  const editTriggerConditionsChanged = newAuto?.editTriggerType
    ? !isEmpty(newAuto?.editTriggerConditions) &&
      (!isEqual(
        prevAutoConfig?.editTriggerConditions,
        newAuto?.editTriggerConditions,
      ) ||
        !isEqual(
          prevAutoConfig?.editTriggerConditionType,
          newAuto?.editTriggerConditionType,
        ))
    : false;

  return (
    editTriggerOnEmptySetChanged ||
    editTriggerTypeChanged ||
    editTriggerColsChanged ||
    editTriggerConditionsChanged
  );
};

const isAddUpdateButtonAvailable = ({
  automationDocumentId,
  setValueFields,
  newAuto,
  currentAutomation,
  automationConfig,
  stateCurrentAutomation,
  stateIsEnabled,
  stateIsEdit,
  currentCol,
  constTemplateValues,
  shouldAddToUserDefined,
  shouldAddToUseUserWhatsappAPI,
  extraDocOperationTypes = [],
  fromMiniApps,
}) => {
  const {
    type: stateType,
    columns: stateColumns,
    operationType: stateOperationType,
    isEnabled: initStateIsEnabled,
    phoneColId: statePhoneColId,
  } = stateCurrentAutomation ?? {};

  const triggerCurrentcolId = findKey(stateColumns);

  const updatedColIdMapping = getUpdatedAutomationColIdMapping(
    newAuto,
    currentAutomation,
  );

  const prevAutoConfig =
    automationConfig?.[currentAutomation?.automationId] ?? {};

  /** operation Type - Variables */
  const operationType = stateOperationType ?? currentAutomation?.operationType;

  const operationTypeData = {
    isOperationType: [
      ...AUTOMATION_DOC_OPERATIONS,
      ...extraDocOperationTypes,
    ].includes(operationType),

    operationType,
    automationDocumentId,
    extraDocOperationTypes,
    setValueFields,

    newAuto,
    prevAutoConfig,
  };

  /** User Defined Data - Variables */
  const isUserDefined = shouldAddToUserDefined();

  /** User Whatsapp Template - Variables */
  const isUserWhatsappTemplate = shouldAddToUseUserWhatsappAPI();

  const reduxConstTemplateValues = constTemplateValues;

  const isAllDataAddedPARAMS = [
    currentAutomation?.type,
    reduxConstTemplateValues,
    newAuto,
    currentCol,
    operationTypeData,
    isUserDefined,
    currentAutomation,
    {fromMiniApps},
  ];

  if (!stateIsEdit) {
    return isAllDataAddedForAddEditButton(...isAllDataAddedPARAMS);
  }

  /* ---------------------------- Below code if for EDIT Automation ---------------------------- */

  // Type Changed or only isEnabled Changed
  const isEnabledChanged = initStateIsEnabled !== stateIsEnabled;

  if (stateType != currentAutomation?.type || isEnabledChanged) {
    return isAllDataAddedForAddEditButton(...isAllDataAddedPARAMS);
  }

  const allTriggerConditionsChanged = checkAllTriggerConditionsChanges(
    newAuto,
    currentAutomation,
    stateCurrentAutomation,
  );

  // ! For MiniApps flow we have added check in code after this condition
  if (
    operationType === AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION &&
    !fromMiniApps
  ) {
    return sendNotificationCheckFN(
      prevAutoConfig,
      newAuto,
      false,
      currentAutomation?.type,
    );
  }

  //prettier-ignore
  const isCELL_STATUS_CHANGE = stateType === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE;
  const isASSIGNEE_CHANGE = stateType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE;

  // ! Column Mapping Checks
  const currentColIdMapping = keys(
    getCurrentAutomationColIdMappingUtils(
      currentAutomation?.automationId,
      automationConfig,
    ) ?? {},
  );
  const changedColIdMap = keys(formatColIdMapFirestore(updatedColIdMapping));
  //prettier-ignore
  const colMappingForWhatsappTemplateChanged = !isEqual(changedColIdMap.sort(), currentColIdMapping.sort());
  // ! Whatsapp Template Checks
  const currentWhatsappTemplateId = isCELL_STATUS_CHANGE
    ? getCellStatusNotifTemplateId(stateCurrentAutomation)
    : prevAutoConfig?.notificationTemplateId;
  const updatedWhatsappTemplateId = getUpdatedNotificationId(
    stateType,
    newAuto,
  );
  //prettier-ignore
  const whatsappTemplateIdChanged = currentWhatsappTemplateId !== updatedWhatsappTemplateId;

  // ! Phone Col Checks
  const updatedPhoneColId = getOriginalColId(newAuto?.phoneColId?.id);
  const currentPhoneColId = statePhoneColId?.id;
  const phoneColChanged = currentPhoneColId != updatedPhoneColId;
  const phoneColIdChanged = isASSIGNEE_CHANGE ? false : phoneColChanged;

  const phoneColCheck = isASSIGNEE_CHANGE
    ? true
    : currentPhoneColId &&
      updatedPhoneColId &&
      !isEqual(currentPhoneColId, updatedPhoneColId);

  // ! Config Change Checks
  //prettier-ignore
  const whatsAppTemplateChangeChecks = colMappingForWhatsappTemplateChanged || whatsappTemplateIdChanged || phoneColIdChanged;
  //prettier-ignore
  const commonConfigChanged = whatsAppTemplateChangeChecks || checkIfUserDefinedDataAdded(isUserDefined, stateCurrentAutomation, newAuto)

  const isEntryBased = ENTRY_BASED.includes(stateType);

  // CELL_STATUS_CHANGE - Checks
  const allStatusChanges = cellStatusChangesCheckFN(
    stateCurrentAutomation,
    newAuto,
    triggerCurrentcolId,
    currentCol,
  );

  const notificationChecks =
    operationType === AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION &&
    fromMiniApps
      ? sendNotificationCheckFN(
          prevAutoConfig,
          newAuto,
          false,
          currentAutomation?.type,
          {fromMiniApps},
        )
      : false;

  const emailChecks =
    operationType === AUTOMATION_OPERATION_TYPES.SEND_EMAIL && fromMiniApps
      ? sendEmailCheck(
          prevAutoConfig,
          newAuto,
          false,
          currentAutomation?.type,
          {fromMiniApps},
        )
      : false;

  // case ON_SPECIFIC_DATE: Checks
  // case ON_ASSIGNEE_CHANGE: Checks
  const specificAutomationCheck = specificAutomationCheckFN(
    newAuto,
    prevAutoConfig,
    whatsAppTemplateChangeChecks,
    stateType,
  );

  const typeCommonChecks = isCELL_STATUS_CHANGE
    ? allStatusChanges || commonConfigChanged
    : isEntryBased
    ? commonConfigChanged
    : false;

  //prettier-ignore
  const notifDataAdded = changedColIdMap && currentColIdMapping && currentWhatsappTemplateId && updatedWhatsappTemplateId;
  const initialMappingCheck = isUserDefined ? true : notifDataAdded;

  //prettier-ignore
  const checkIfConstValueChanged = prevAutoConfig?.constTemplateValues && reduxConstTemplateValues && !isEqual(prevAutoConfig?.constTemplateValues, reduxConstTemplateValues);

  const checkIfEntryModifiedConfigChanged =
    currentAutomation?.type === MESSAGE_AUTOMATION.ROW_EDIT_ENTRY
      ? checkEntryModifiedConfigChanges(prevAutoConfig, newAuto)
      : false;

  const isNameChanged = !isEqual(prevAutoConfig?.name, newAuto?.name);
  const isCustomNotificationTextChanged = !isEqual(
    prevAutoConfig?.customNotificationText,
    newAuto?.customNotificationText,
  );

  const tableViewChecks = fromMiniApps
    ? false
    : initialMappingCheck && phoneColCheck && typeCommonChecks;

  const IS_WHATSAPP_MESSAGE = [
    '',
    undefined,
    null,
    DEFAULT_OPERATION_TYPE,
  ].includes(currentAutomation?.operationType);

  const whatsAppTemplateMiniAppsChecks =
    fromMiniApps && IS_WHATSAPP_MESSAGE
      ? initialMappingCheck
        ? //prettier-ignore
          (currentPhoneColId && updatedPhoneColId && !isEqual(currentPhoneColId, updatedPhoneColId)) ||
          //prettier-ignore
          commonConfigChanged
        : false
      : false;

  const miniAppsChanges = fromMiniApps
    ? isNameChanged ||
      checkIfConstValueChanged ||
      allTriggerConditionsChanged ||
      checkIfEntryModifiedConfigChanged ||
      notificationChecks ||
      emailChecks ||
      allStatusChanges ||
      whatsAppTemplateMiniAppsChecks ||
      isCustomNotificationTextChanged
    : false;

  return (
    miniAppsChanges ||
    tableViewChecks ||
    (isEntryBased ? specificAutomationCheck : false) ||
    operationTypeChecksFN(operationTypeData, {fromMiniApps})
  );
};

const getCellStatusNotifTemplateId = (automationData) => {
  try {
    const {columns} = automationData ?? {};
    const colId = findKey(columns ?? {});
    if (colId) {
      const {triggers} = columns?.[colId] ?? {};
      const thisStatus = findKey(triggers ?? {});
      const {notificationTemplateId} = triggers?.[thisStatus] ?? {};
      return notificationTemplateId ?? null;
    }
    return null;
  } catch (error) {
    captureError(error);
    return null;
  }
};

const getCurrentNotificationTemplateId = (
  automationConfig,
  currentAutomation,
) => {
  const automationData = automationConfig?.[currentAutomation?.automationId];

  return ENTRY_BASED.includes(currentAutomation?.type)
    ? automationData?.notificationTemplateId
    : getCellStatusNotifTemplateId(automationData);
};

const getUpdatedNotificationTemplateId = (currentAutomation, newAuto) => {
  return ENTRY_BASED.includes(currentAutomation?.type)
    ? newAuto?.notificationTemplateId
    : newAuto?.triggerData?.[0]?.notificationTemplateId;
};

const getFilterListForNotificationSelection = (params, reduxState) => {
  const {newAuto, currentAutomation, campaignColIdMapping} = reduxState;

  const currentTriggerObj =
    newAuto?.triggerData?.[automationConstants.addToTriggerDataIndex]
      ?.colIdMapping ?? {};

  const currentNewEntryColIdMapping = newAuto?.colIdMapping ?? {};

  return Object.keys(
    params.isCampaign
      ? campaignColIdMapping
      : automationUtils.checkIsTriggerAutomation(currentAutomation)
      ? currentTriggerObj
      : currentNewEntryColIdMapping,
  )
    .filter((item) => {
      return `${item}` !== `${params?.id}`;
    })
    .map(
      (item) =>
        (params.isCampaign
          ? campaignColIdMapping
          : automationUtils.checkIsTriggerAutomation(currentAutomation)
          ? currentTriggerObj
          : currentNewEntryColIdMapping)[item].id,
    );
};

const templateNotificationKeys = (reduxState, extra = {}) => {
  const {automationTemplates, currentAutomation, newAuto} = reduxState;
  const notification_template_id = extra?.templateId?.length
    ? extra?.templateId
    : getAutomationUpdatedTemplateId(currentAutomation, newAuto);

  const templateData = getNotificationTemplate(
    automationTemplates,
    notification_template_id,
  );

  const keyValuesObj = templateData?.keysValues ?? {};
  const removeKeys = [];
  Object.keys(keyValuesObj).forEach((key) => {
    if (MESSAGE_AUTOMATION_REMOVE_KEYS.includes(keyValuesObj[key])) {
      removeKeys.push(key);
    }
  });
  return !isEmpty(keyValuesObj) ? omit(keyValuesObj, removeKeys) : {};
};

const templateMappingContinueEnabled = (reduxState) => {
  try {
    const {
      newAuto,
      userPref,
      constTemplateValues,
      currentAutomation,
      automationTemplates,
      isOrganisationMode,
      organisationProfileData,
    } = reduxState;

    const notification_template_id = getAutomationUpdatedTemplateId(
      currentAutomation,
      newAuto,
    );

    const templateData = getNotificationTemplate(
      automationTemplates,
      notification_template_id,
    );
    const {triggerData, colIdMapping} = newAuto ?? {};
    const index = automationConstants.addToTriggerDataIndex;
    const addedKeys = Object.keys(
      (automationUtils.checkIsTriggerAutomation(currentAutomation)
        ? triggerData?.[index]?.colIdMapping
        : colIdMapping) ?? {},
    );

    const name = isOrganisationMode
      ? organisationProfileData.orgName || ''
      : userPref.businessName;

    const isProfileFieldsAdded = Boolean(
      isOrganisationMode ? true : name ? name?.length : true,
    );

    const customKeysLength = Object.keys(constTemplateValues)?.length;

    const notificationKeys = Object.values(templateData?.keys ?? {}).filter(
      (item) => !MESSAGE_AUTOMATION_REMOVE_KEYS.includes(item),
    );
    return (
      notificationKeys.length > 0 &&
      (addedKeys.length > 0 || customKeysLength > 0) &&
      notificationKeys.length === addedKeys.length + customKeysLength &&
      isProfileFieldsAdded
    );
  } catch (error) {
    captureError(error);
    return false;
  }
};

const templateMappingContinueEnabledV2 = (
  reduxState,
  colIdMapping,
  constTemplateValues,
  templateId,
) => {
  try {
    const {
      userPref,
      automationTemplates,
      isOrganisationMode,
      organisationProfileData,
    } = reduxState;

    const templateData = getNotificationTemplate(
      automationTemplates,
      templateId,
    );

    const addedKeys = Object.keys(colIdMapping || {});

    const name = isOrganisationMode
      ? organisationProfileData.orgName || ''
      : userPref.businessName;

    const isProfileFieldsAdded = Boolean(
      isOrganisationMode ? true : name ? name?.length : true,
    );

    const customKeysLength = Object.keys(constTemplateValues || {})?.length;

    const notificationKeys = Object.values(templateData?.keys ?? {}).filter(
      (item) => !MESSAGE_AUTOMATION_REMOVE_KEYS.includes(item),
    );
    return (
      notificationKeys.length > 0 &&
      (addedKeys.length > 0 || customKeysLength > 0) &&
      notificationKeys.length === addedKeys.length + customKeysLength &&
      isProfileFieldsAdded
    );
  } catch (error) {
    captureError(error);
    return false;
  }
};

const getColumnScreenFlatlistData = (params, reduxState) => {
  const {selectType, updatePhoneCol, miniAppsColumnSelectEvent, filterColumns} =
    params ?? {};
  const headerData = reduxState?.headerData ?? [];
  const {currentCol, editTriggerCol, automationConfig, currentAutomation} =
    reduxState;

  const automationDataColumns =
    automationConfig?.[currentAutomation?.automationId]?.columns;

  const alreadyUsedColIds = Object.keys(automationDataColumns ?? {}).filter(
    (item) => ![currentCol?.id, editTriggerCol?.id].includes(item),
  );

  return selectType === 'TRIGGER_COLUMN'
    ? headerData.filter((item) => {
        const cond1 = item.fieldType === FIELD_TYPE_ID.SELECT_POP_UP;
        const cond2 = !alreadyUsedColIds.includes(`${item.id}`);
        return cond1 && cond2;
      })
    : selectType === 'NOTIFICATION_COLUMN'
    ? headerData.filter((item) => {
        return (
          /**
           * We are removing previous row reference as we are calculating them during render.
           * */
          !item?.hasPrevRowRef &&
          AUTO_MESSAGE_ALLOWED_FIELDS.includes(getOriginalFieldType(item)) &&
          !(miniAppsColumnSelectEvent
            ? filterColumns?.includes(item.id)
            : getFilterListForNotificationSelection(
                params,
                reduxState,
              ).includes(item.id))
        );
      })
    : selectType === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE
    ? headerData.filter((item) =>
        [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME].includes(
          getOriginalFieldType(item),
        ),
      )
    : updatePhoneCol
    ? headerData.filter((item) =>
        [FIELD_TYPE_ID.CONTACT, FIELD_TYPE_ID.ASSIGN_TASK].includes(
          getOriginalFieldType(item),
        ),
      )
    : [];
};

/* Split camel cased column name into two words.
   Input: businessName 
   Outut: Business Name
*/
const getColumnRegex = (item) => {
  try {
    return capitalize(item?.replace?.(/([a-z])([A-Z])/g, '$1 $2'));
  } catch (error) {
    captureError(error);
    return '';
  }
};

const checkIsTriggerAutomation = (currentAutomation) => {
  return currentAutomation?.type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE;
};

export const automationConstants = {
  // Don't Change this
  addToTriggerDataIndex: 0, // Will always be zero as single element is allowed.
};

/**
 * Function to get Display message for Custom Template.
 * @param {object} {newAuto: redux automation data, stateCurrentAutomation: local state saved data.}
 * @returns {string} Display Message.
 */
const getUserDefinedTemplateDisplayMessage = ({
  newAuto,
  stateCurrentAutomation,
}) => {
  const str =
    newAuto?.userDefined?.showMessage ??
    stateCurrentAutomation?.showMessage ??
    '';
  return getTrailText(str.replace(/\n/gm, ''), 30);
};

const getTemplateFilterArray = (filterArr, userPref) => {
  const arr = (filterArr ?? []).map((item) => ({
    name: item.name?.[userPref?.lang || 'EN'] ?? item.name?.['EN'] ?? '',
    type: item.type,
  }));
  return [{name: getLocalText(userPref, 'All'), type: 'ALL'}, ...arr];
};

const sortTemplatesArray = (sortArr, userPref, isSelectedCheckFunc) => {
  const forSelected = (obj) => (isSelectedCheckFunc(obj?.templateId) ? -1 : 1);

  let arr = (isArray(sortArr) ? sortArr : []).sort(forSelected);

  if (arr.length) {
    if (isSelectedCheckFunc(arr?.[0]?.templateId)) {
      arr = [
        arr[0],
        ...(arr.length > 1 ? sortBy(arr.slice(1), ['title']) : []),
      ];
    } else {
      arr = sortBy(arr, ['title']);
    }
  }

  return arr;
};

const isAutomationDataAvailable = (
  rowObj,
  automationConfig,
  headerTypeMapping,
  currentColId,
  previousRowValue,
  automationId,
  isAlreadyAdded,
) => {
  const {rowProperties} = rowObj ?? {};
  const {
    type,
    operationType,
    isEnabled,
    headerMapping,
    userDefined,
    triggerCol,
    phoneColId: phoneColData,
    colIdMapping,
  } = automationConfig ?? {};
  const {triggerdAutomation} = rowProperties ?? {};
  if (
    !triggerBASED_AUTOMATIONS.includes(type) &&
    triggerdAutomation?.[automationId]
  ) {
    return false;
  }

  const isDocOperationType = AUTOMATION_DOC_OPERATIONS.includes(operationType);

  const current_col_id = getOriginalColId(currentColId);

  const pendingAutomationsOmitKeys = [
    MESSAGE_AUTOMATION.CELL_STATUS_CHANGE.toLowerCase(),
    MESSAGE_AUTOMATION.ROW_NEW_ENTRY.toLowerCase(),
  ];

  try {
    if (
      !isEnabled ||
      type === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE || // ! Remove when we want to enable assign task automation on table
      // OMIT below in case automation added from Automation-V1.
      pendingAutomationsOmitKeys.includes(automationId)
    ) {
      return false;
    }

    const userDefinedCols = (userDefined?.columns ?? []).map((item) =>
      getOriginalColId(item?.id),
    );

    // ---------------- SEND NOTIFICATION CHECK ----------------
    const isSendNotification =
      operationType === AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION;
    if (ENTRY_BASED.includes(type) && isSendNotification) {
      const rowDataToCheck = omit(rowObj, OMIT_ROW_KEYS_FOR_COLIDS);
      const colIds = Object.keys(rowDataToCheck);
      return colIds.some((id) => {
        const prevVal = previousRowValue[id]?.val;
        const valueExistOnCell = checkIfValueExistOnCell(
          rowObj[id],
          headerTypeMapping[id],
        );
        return (
          id in rowObj &&
          (isEmpty(prevVal) || !prevVal?.length) &&
          valueExistOnCell
        );
      });
    }

    if (ENTRY_BASED.includes(type)) {
      const colIds = isDocOperationType
        ? Object.keys(headerMapping || {})
        : !isEmpty(userDefined)
        ? userDefinedCols
        : Object.keys(colIdMapping || {}).map((item) => getOriginalColId(item));

      const triggerColId = triggerBASED_AUTOMATIONS.includes(type)
        ? triggerCol?.id
        : null;

      const phoneColId = getOriginalColId(phoneColData?.id);

      if (
        !isDocOperationType &&
        type !== MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE &&
        !colIds.includes(phoneColId)
      ) {
        colIds.push(phoneColId);
      }

      if (triggerColId && !colIds.includes(triggerColId)) {
        colIds.push(triggerColId);
      }

      let shouldRun = false;

      const allDataAvailable = colIds.every((id) => {
        return (
          id in rowObj &&
          checkIfValueExistOnCell(rowObj[id], headerTypeMapping[id])
        );
      });
      if (current_col_id && !colIds.includes(current_col_id)) {
        if (!isEmpty(previousRowValue)) {
          colIds.forEach((col_id) => {
            // TODO : Ask Anurag/Aniket on how to handle ASSIGNEE CHANGE over here.
            // CASE 1: Run Only If Assignee is changed.
            // CASE 2: Run Even if any data is changed
            if (!isEqual(rowObj[col_id], previousRowValue[col_id])) {
              shouldRun = true;
            }
          });
        }
        // If both row object are different then add to pending automation...
        if (!shouldRun) {
          return isAlreadyAdded ? allDataAvailable : false;
        }
      }
      // return Boolean - if all data Available for given columns or not
      return allDataAvailable;
    } else if (type === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE) {
      const {columns: cellStatusCols} = automationConfig;
      // handle trigger automations
      const triggerColIds = Object.keys(cellStatusCols).map((item) => {
        return getOriginalColId(item);
      });

      if (phoneColData?.id in rowObj || isDocOperationType) {
        const triggerCols = {};
        triggerColIds.forEach((tc, index) => {
          if (tc in rowObj) {
            const val = rowObj[tc]?.val; // Ensuring trigger val is string. As we are getting keys as string.
            const colTriggers = Object.keys(cellStatusCols[tc].triggers);
            /**
             * Weak Value check as some times the status might be in number
             */
            if (
              colTriggers.findIndex((value) => `${value}` == `${val}`) !== -1
            ) {
              const {triggers} = cellStatusCols?.[tc] ?? {};
              const {colIdMapping: colsCellStatus} = triggers?.[`${val}`] ?? {};

              if (isDocOperationType) {
                triggerCols[tc] = [
                  ...Object.keys(headerMapping ?? {}).map((item) =>
                    getOriginalColId(item),
                  ),
                  getOriginalColId(tc),
                ]; // Dropdown is Dependent Column
              } else {
                triggerCols[tc] = [
                  ...(!isEmpty(userDefined)
                    ? userDefinedCols
                    : Object.keys(colsCellStatus ?? {}).map((item) =>
                        getOriginalColId(item),
                      )),
                  getOriginalColId(phoneColData?.id),
                  getOriginalColId(tc),
                ];
              }
            }
          }
        });

        const foundColums = [];
        let saveAutomation = false;
        Object.keys(triggerCols).forEach((col, index) => {
          const statusColId = col;
          let dependentCols = triggerCols[statusColId];
          dependentCols = difference(dependentCols, foundColums).map((item) => {
            return getOriginalColId(item);
          });
          let shouldRun = false;

          if (
            current_col_id &&
            (!dependentCols.includes(current_col_id) ||
              statusColId == currentColId)
          ) {
            if (!isEmpty(previousRowValue)) {
              const {triggers} = cellStatusCols?.[statusColId] ?? {};
              const {val} = rowObj[statusColId] ?? {};
              const valAvailable = isDocOperationType
                ? !isEmpty(headerMapping)
                : triggers?.[val]?.colIdMapping ||
                  triggers?.[`${val}`]?.colIdMapping ||
                  {};

              dependentCols.forEach((col_id) => {
                if (
                  !isEqual(rowObj[col_id], previousRowValue[col_id]) &&
                  valAvailable
                ) {
                  shouldRun = true;
                }
              });
            }
            // If both row object are different then add to pending automation...
            if (!shouldRun) {
              // Pass "false" to saveAutomation if value not available for status column
              // Pass "null" to saveAutomation if not in dependent columns
              saveAutomation = statusColId == currentColId ? false : null;
              // Continue to next iteration if not in dependent columns
              return null;
            }
          }
          const allColumnsAvailable = dependentCols.every((id) => {
            const columnFound =
              id in rowObj &&
              checkIfValueExistOnCell(rowObj[id], headerTypeMapping[id]);
            if (columnFound) {
              foundColums.push(id);
            }
            return columnFound;
          });
          saveAutomation = allColumnsAvailable;
        });
        return saveAutomation;
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const getAutomationColumnsWithPrevRowRef = (
  dependentColsForCurrentAutomation,
  headerData,
) => {
  try {
    if (!dependentColsForCurrentAutomation?.length || !headerData?.length) {
      return {};
    }

    const dependentColsData = headerData.filter((obj) =>
      dependentColsForCurrentAutomation.includes(obj.id),
    );
    const prevRowRefColumns = [];
    for (const colObj of dependentColsData) {
      if (colObj.hasPrevRowRef) {
        prevRowRefColumns.push({id: colObj.id, name: colObj.val});
      }
    }
    return prevRowRefColumns;
  } catch (error) {
    captureError(error);
  }
};

const getAutomationDisplayData = (automationConfig, headerData, userPref) => {
  let cellStatusColIdName = null;
  let cellStatusTriggerStatusName = null;

  const headerTypeMapping = getHeaderTypeMapping(headerData);
  const cellStatusCols = automationConfig?.columns;
  const colId = getOriginalColId(findKey(cellStatusCols));

  if (colId) {
    cellStatusColIdName = headerTypeMapping?.[colId]?.val ?? '';
    const cellStatusTriggerStatusVal = findKey(
      automationConfig?.columns?.[colId]?.triggers,
    );
    const cellStatusTriggerStatus =
      headerTypeMapping?.[colId]?.selectElements?.find?.(
        (item) => item.val == cellStatusTriggerStatusVal,
      ) ?? -1;

    cellStatusTriggerStatusName =
      cellStatusTriggerStatus?.displayName?.[userPref?.lang ?? 'EN'] ||
      cellStatusTriggerStatus?.val ||
      cellStatusTriggerStatusVal ||
      '';
  }

  const automationType = automationConfig?.type;

  const placeHolderText =
    automationType === MESSAGE_AUTOMATION.MANUAL
      ? 'Manually Triggered'
      : automationType === MESSAGE_AUTOMATION.ON_USER_APP_ACCESS
      ? 'On App Access Given'
      : automationType === MESSAGE_AUTOMATION.ROW_NEW_ENTRY
      ? 'New Entry'
      : automationType === MESSAGE_AUTOMATION.ROW_EDIT_ENTRY
      ? 'Edit Entry'
      : automationType === MESSAGE_AUTOMATION.ROW_DELETE_ENTRY
      ? 'Delete Entry'
      : automationType === MESSAGE_AUTOMATION.ON_NEW_COMMENT
      ? 'On New Comment'
      : automationType === MESSAGE_AUTOMATION.ON_SPECIFIC_DATE
      ? 'On Specific Date'
      : automationType === MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE
      ? 'On Assignee Change'
      : automationType === MESSAGE_AUTOMATION.CELL_STATUS_CHANGE
      ? cellStatusColIdName + ' / ' + cellStatusTriggerStatusName
      : '';

  const itemDescription = getLocalText(
    userPref,
    'Trigger: TRIGGER_PLACEHOLDER_TEXT',
  ).replace('TRIGGER_PLACEHOLDER_TEXT', placeHolderText);

  return {itemDescription};
};

const getAvailableColumns = (
  columnType,
  headerData,
  headerMapping,
  headerObj,
) => {
  let temp = [];

  temp = headerData?.filter((item) => {
    if (columnType === FIELD_TYPE_ID.AUTO_INCREMENT_ID) {
      if (item.fieldType === FIELD_TYPE_ID.TABLE) {
        return (
          !Object?.values(headerMapping).includes(item?.id) &&
          item.subType !== columnType
        );
      } else {
        return (
          !Object?.values(headerMapping).includes(item?.id) &&
          item.fieldType !== columnType
        );
      }
    }

    if (columnType === 'TABLE') {
      if (headerObj.subType === FIELD_TYPE_ID.AUTO_INCREMENT_ID) {
        if (item.fieldType === FIELD_TYPE_ID.TABLE) {
          return (
            !Object?.values(headerMapping).includes(item?.id) &&
            headerObj.subType !== item.subType
          );
        }
        return (
          !Object?.values(headerMapping).includes(item?.id) &&
          headerObj.subType !== item.fieldType
        );
      } else {
        return (
          (columnType == item?.fieldType ||
            headerObj.subType == item.fieldType) &&
          !Object?.values(headerMapping).includes(item?.id)
        );
      }
    } else if (columnType === 'FORMULA') {
      return (
        ([FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(headerObj.subType)
          ? item.fieldType === FIELD_TYPE_ID.TEXT
          : ALLOW_FORMULA_SUBTYPE_AUTOMATION.includes(item.fieldType)) &&
        !Object?.values(headerMapping).includes(item?.id)
      );
    } else {
      return (
        (columnType == item?.fieldType ||
          (item.fieldType == 'TABLE' && columnType == item?.subType)) &&
        !Object?.values(headerMapping).includes(item?.id)
      );
    }
  });
  temp?.push({val: `Don't Copy`, id: 'DONT_COPY'});
  return temp;
};
/**
 *
 * @param {object} state
 * @returns {{automationHeaderData: Array; automationFileObj: object}}
 */
const getAutomationTableState = (state) => {
  const fromMiniApps = state.automation.fromMiniApps;
  const docId = state.automation.automationDocumentId;
  return {
    automationHeaderData: fromMiniApps
      ? state.miniApps.docsData?.[docId]?.headerData?.length
        ? state.miniApps.docsData[docId].headerData
        : []
      : state.table.headerData?.length
      ? state.table.headerData
      : [],

    automationFileObj: fromMiniApps
      ? state.miniApps.docsData?.[docId]?.fileObj || {}
      : state.table.fileObj || {},
  };
};

const getAutomationDocumentMeta = (state) => {
  const fromMiniApps = state.automation.fromMiniApps;

  return fromMiniApps
    ? state.automation.automationDocumentMeta
    : state.home.activeDocumentMeta;
};

const getUserTypeVal = (newAuto) => {
  const userType = newAuto?.userType;
  return NOTIFICATION_USER_TYPES_OBJECT[userType]?.val ?? '';
};
const handleAddonCallBack = (organisation, userPref, hideToast = false) => {
  const {profileData, activeOrganisationId} = organisation ?? {};
  return callCloudFunction(CLOUD_FUNCTION_PATHS.POST_API_HANDLER, {
    body: {orgName: profileData?.orgName, orgId: activeOrganisationId},
    type: API_TYPES.SLACK_ADD_ON_REQUEST,
  })
    .then(() => {
      if (hideToast) return;
      ShowToast('Request Recieved Successfully', userPref);
    })
    .catch((error) => {
      console.error('Error while sending slack add on request', error);
    });
};

const miniAppAutomationMapToList = (obj, appId) => {
  const result = Object.keys(obj).reduce((acc, docId) => {
    const automationsObj = obj[docId];
    const automationArrToSend = Object.keys(automationsObj).map(
      (automationItem) => ({
        ...(automationsObj?.[automationItem] || {}),
        automationId: automationItem,
        docId,
      }),
    );

    const automations = automationArrToSend.filter(
      (config) => config.miniAppId === appId,
    );

    return [...acc, ...automations];
  }, []);

  return sortBy(result, [(o) => (o.name || '').toLowerCase()]);
};

// const getChooseFieldsForSetValue = (headerData, triggerType) => {
const getChooseFieldsForSetValue = (headerData) => {
  return filter(headerData, (col) => {
    const {fieldType, subType, columnProperties} = col;
    const isAutoIncrementGenerate =
      fieldType === FIELD_TYPE_ID.AUTO_INCREMENT_ID &&
      columnProperties?.[COLUMN_PROPERTY_KEYS.GENERATE_MANUALLY];
    if (isAutoIncrementGenerate) {
      return true;
    }

    const DISABLED_FIELD_TYPES = [
      ...DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS_V2,
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.STRING_FORMULA,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.NO_OF_DAYS,
      // ...(triggerType === MESSAGE_AUTOMATION.ON_USER_APP_ACCESS
      //   ? [
      //       FIELD_TYPE_ID.DOCUMENT,
      //       FIELD_TYPE_ID.AUDIO,
      //       FIELD_TYPE_ID.VIDEO,
      //       FIELD_TYPE_ID.IMAGE,
      //     ]
      //   : []),
    ];

    // Remove Table Columns without `subType`
    if (fieldType === FIELD_TYPE_ID.TABLE && !subType) {
      return false;
    }

    if (
      fieldType === FIELD_TYPE_ID.TABLE &&
      [FIELD_TYPE_ID.STRING_FORMULA, FIELD_TYPE_ID.AUTO_INCREMENT_ID].includes(
        subType,
      )
    ) {
      return true;
    }

    if (DISABLED_FIELD_TYPES.includes(getOriginalFieldType(col))) {
      return false;
    }

    return true;
  });
};

const formulaToAny = (selectedColObj, colObj) => {
  const {eqn: itemEqn} = colObj || {};

  let allowFormula = true;

  const hasDateFieldInEquation = !itemEqn?.length
    ? false
    : itemEqn.find((col) => {
        return (
          ALL_DATE_TIME_FIELDS.includes(col?.fieldType) ||
          ALL_DATE_TIME_FIELDS.includes(col?.subType)
        );
      });

  // Filter out `DATE` / `TIME` included formula columns
  if (hasDateFieldInEquation) {
    allowFormula = false;
  }

  const originalSelectedFieldType = getOriginalFieldType(selectedColObj);

  const isFormulaIncludedInMapping = FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP?.[
    originalSelectedFieldType
  ]?.includes?.(FIELD_TYPE_ID.FORMULA);

  if (!isFormulaIncludedInMapping) {
    allowFormula = false;
  }

  // const selectedFieldTypeText =
  //   originalSelectedFieldType === FIELD_TYPE_ID.TEXT;

  // if (dateTimeFields.includes(colObj.subType) && !selectedFieldTypeText) {
  //   allowFormula = false;
  // }

  return allowFormula;
};
const autoIncrementTable = (colObj) => {
  return (
    colObj?.fieldType === FIELD_TYPE_ID.TABLE &&
    [FIELD_TYPE_ID.AUTO_INCREMENT_ID, FIELD_TYPE_ID.STRING_FORMULA].includes(
      colObj?.subType,
    )
  );
};
const isMappingAllowed = (
  colObject,
  col,
  isTriggerType,
  triggerConfigDocId,
) => {
  const originalSelectedFieldType = getOriginalFieldType(colObject);
  const originalFieldType = getOriginalFieldType(col);
  const isAutoIncrementOrStringFormulaTableDest = autoIncrementTable(colObject);
  const isAutoIncrementOrStringFormulaTableSource = autoIncrementTable(col);
  const isAutoIncrementOrStringFormulaSource = [
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.STRING_FORMULA,
  ].includes(col.fieldType);

  if (
    !FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP[originalSelectedFieldType] &&
    !(
      colObject?.fieldType === FIELD_TYPE_ID.TABLE &&
      colObject?.subType === FIELD_TYPE_ID.STRING_FORMULA
    )
  ) {
    return false;
  }

  // * Handled only for `fieldType` as FORMULA and not TABLE(FORMULA) (As we don't know the end result of TABLE(FORMULA)).
  if (col?.fieldType === FIELD_TYPE_ID.FORMULA) {
    return formulaToAny(colObject, col);
  }

  if (isAutoIncrementOrStringFormulaTableDest && isTriggerType) {
    let destColId, destFileId, sourceColId, sourceFileId;
    if (isAutoIncrementOrStringFormulaTableSource) {
      if (!isEmpty(colObject?.linkedMeta) && !isEmpty(col?.linkedMeta)) {
        destFileId = Object.keys(colObject?.linkedMeta)[0];
        destColId = colObject?.linkedMeta[destFileId]?.colId;
        sourceFileId = Object.keys(col?.linkedMeta)[0];
        sourceColId = col?.linkedMeta[sourceFileId]?.colId;
      }
    } else if (isAutoIncrementOrStringFormulaSource) {
      if (!isEmpty(colObject?.linkedMeta)) {
        destFileId = Object.keys(colObject?.linkedMeta)[0];
        destColId = colObject?.linkedMeta[destFileId]?.colId;
        sourceFileId = triggerConfigDocId;
        sourceColId = col?.id;
      }
    }
    if (
      destFileId &&
      sourceFileId &&
      destColId &&
      sourceColId &&
      destFileId === sourceFileId &&
      destColId === sourceColId
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP[originalSelectedFieldType] || []
  ).includes(originalFieldType);
};

const getFieldsForSetConditionMapping = (headerData, colObject) => {
  const originalSelectedFieldType = getOriginalFieldType(colObject);

  return headerData?.filter?.((col) => {
    const originalFieldType = getOriginalFieldType(col);

    const extraConditionFieldTypes =
      FIELD_TYPE_TO_OTHER_FIELD_TYPE_CONDITIONS_MAP[originalSelectedFieldType];

    if (
      extraConditionFieldTypes &&
      extraConditionFieldTypes.includes(originalFieldType)
    ) {
      // Below condition will allow only UNIT / RUPEE / NUMBER. Field Types.
      if (
        originalSelectedFieldType === FIELD_TYPE_ID.FORMULA &&
        (ALL_DATE_TIME_FIELDS.includes(colObject?.subType) ||
          !colObject?.subType)
      ) {
        return false;
      }
      return true;
    }

    return isMappingAllowed(colObject, col, false);
  });
};

const getFieldForValueMapping = (
  headerData,
  selectedColObj,
  searchText,
  options = {
    shouldDisableAllColumnsForAutoIncrement: false,
    sameDocumentSelected: false,
    alreadyMappedColumns: [],
    operationType: null,
    currentMappedId: null,
    mappingVersion: '',
    triggerConfigDocId: '',
  },
) => {
  const {
    fieldType: selectFieldType,
    subType: selectSubType,
    val: columnName,
    id: toColId,
  } = selectedColObj ?? {};

  const {
    shouldDisableAllColumnsForAutoIncrement,
    sameDocumentSelected,
    operationType,
    alreadyMappedColumns, // headerSelectedValues
    currentMappedId, // selectedId
    mappingVersion,
    triggerConfigDocId,
  } = options || {};

  const fieldTypeChecks = (item) => {
    // Target Column will never be FORMULA / TABLE(FORMULA).
    const isSourceTable = item.fieldType === FIELD_TYPE_ID.TABLE;

    // Remove Table Columns without `subType`
    if (isSourceTable && !item.subType) {
      return false;
    }

    // Remove Source Table Columns with `subType` as FORMULA
    // if (isSourceTable && item.subType === FIELD_TYPE_ID.FORMULA) {
    //   return false;
    // }

    return isMappingAllowed(selectedColObj, item, true, triggerConfigDocId);
  };

  const docAutomationTypes = [
    ...AUTOMATION_DOC_OPERATIONS,
    AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
  ];

  return headerData
    .filter((item) => {
      //prettier-ignore
      // const autoIncrementDisabled = shouldDisableAllColumnsForAutoIncrement && selectFieldType === FIELD_TYPE_ID.AUTO_INCREMENT_ID;

      //prettier-ignore
      const colDisabledForSameDoc = operationType !== AUTOMATION_OPERATION_TYPES.COPY_ROW &&  sameDocumentSelected && toColId === item.id;

      const hasDisabledColumnTypesForDocAutomations =
        docAutomationTypes.includes(operationType) &&
        DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS_V2.includes(
          // Disable Table based columns for Document Automation.
          getOriginalFieldType(item),
        );

      if (
        // autoIncrementDisabled ||
        colDisabledForSameDoc ||
        hasDisabledColumnTypesForDocAutomations
      ) {
        return false;
      }

      // Render Current Selected Column for Mobile
      // - Web passes `currentMappedId` as null.
      if (item.id === currentMappedId) {
        return true;
      }

      const isAlreadySelectedColumnForOtherTargetColumn =
        !mappingVersion && (alreadyMappedColumns ?? []).includes(item.id);

      // Remove already selected columns of other target columns.
      if (isAlreadySelectedColumnForOtherTargetColumn) {
        return false;
      }
      return fieldTypeChecks(item);
    })
    .filter((item) =>
      searchText.length
        ? item.val.toLowerCase().includes(searchText.toLowerCase())
        : true,
    );
};

const filterAvailableAutomationsForTable = (availableAutomations) => {
  return omitBy(availableAutomations, (automation, key) => {
    const {operationType} = automation || {};
    const omitFor = [AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS];
    if (
      omitFor.includes(operationType) || // For Automation Config  / Available Automation
      automation?.type === MESSAGE_AUTOMATION.MANUAL || // For Automation Config
      key === 'manual' // For Available Automation
    ) {
      return true;
    }
    return false;
  });
};

const setConditionComponentValueDisplayText = (id, colObj) => {
  const fieldType = getColumnFieldType(colObj);
  if (id === AUTOMATION_COLUMN_VALUE_TYPES.NOW) {
    if (fieldType === FIELD_TYPE_ID.DATE) {
      const dateFormat =
        colObj?.dateFormat in DATE_FORMATS
          ? colObj.dateFormat
          : DATE_FORMATS.DATE;
      return `Current ${capitalize(dateFormat)}`;
    }

    if (fieldType === FIELD_TYPE_ID.TIME) {
      return 'Current Time';
    }

    if (fieldType === FIELD_TYPE_ID.DATE_TIME) {
      return 'Current Date Time';
    }
  }

  if (id === AUTOMATION_COLUMN_VALUE_TYPES.SET_EMPTY) {
    return 'Set Empty';
  }

  const mapval = MAPPING_DEFAULT_VALUES_DETAILS[id]?.val;
  return mapval || null;
};

/**
 * Function to get title for value mapping
 * @param {object} fromHeaderDataIdMapping
 * @param {object} toHeaderDataIdMapping
 * @param {object} reduxObject
 * @param {object | null} reduxObject.headerMapping
 * @param {object | null} reduxObject.defaultValMap
 * @param {object | null} reduxObject.defaultValConstantMap
 * @param {object | null} reduxObject.defaultValConstantMapOptions
 *
 * @return {string} title
 *
 */
const getTitleForValueMapping = (
  fromHeaderDataIdMapping,
  toHeaderDataIdMapping,
  reduxObject,
  toColId,

  //Additonal Source Props
  docsData,
  additionalDataSource,
) => {
  if (reduxObject?.headerMapping[toColId]) {
    return (
      fromHeaderDataIdMapping?.[reduxObject?.headerMapping?.[toColId]]?.val ||
      'Select Value'
    );
  }

  if (reduxObject?.defaultValMap?.[toColId]) {
    return 'Custom Value';
  }

  // TODO : INCREMENT_BY / DECREMENT_BY (need to handle options object)
  const constVal = reduxObject?.defaultValConstantMap?.[toColId];
  const {fieldType: toFieldType} = toHeaderDataIdMapping?.[toColId] ?? {};

  if (constVal === AUTOMATION_COLUMN_VALUE_TYPES.NOW) {
    if (toFieldType === FIELD_TYPE_ID.DATE) {
      const dateFormat =
        toHeaderDataIdMapping?.[toColId]?.dateFormat in DATE_FORMATS
          ? toHeaderDataIdMapping[toColId].dateFormat
          : DATE_FORMATS.DATE;
      return `Current ${capitalize(dateFormat)}`;
    }

    if (toFieldType === FIELD_TYPE_ID.TIME) {
      return 'Current Time';
    }

    if (toFieldType === FIELD_TYPE_ID.DATE_TIME) {
      return 'Current Date Time';
    }
  }

  if (constVal === AUTOMATION_COLUMN_VALUE_TYPES.SET_EMPTY) {
    return 'Set Empty';
  }
  if (constVal === AUTOMATION_COLUMN_VALUE_TYPES.SET_VALUE_ON_RUNTIME) {
    return 'Set Value On Runtime';
  }
  const mapval = MAPPING_DEFAULT_VALUES_DETAILS[constVal]?.val;
  if (mapval) {
    if (
      constVal === AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY ||
      constVal === AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY
    ) {
      const {value, type, aggregationInfo, endpointResponseInfo} =
        reduxObject?.defaultValConstantMapOptions?.[toColId] ?? {};
      const text =
        type === 'CUSTOM_VALUE'
          ? 'Custom Value'
          : type === 'ENDPOINT'
          ? endpointResponseInfo?.title
          : type === 'AGGREGATION'
          ? (reduxObject.aggregationMap?.columns ?? []).find(
              ({renderId}) => value === renderId,
            )?.val ?? aggregationInfo.title
          : fromHeaderDataIdMapping?.[value]?.val;
      return `${mapval} ( ${text} )`;
    }
    return mapval;
  }

  //Additional Data SOURCE additionalDataSource  :- {}
  let additionalSourceMapTitle;
  forOwn(additionalDataSource, (value, key) => {
    if (value?.headerMapping?.[toColId]) {
      additionalSourceMapTitle =
        docsData?.[key]?.headerDataAsObj?.[value?.headerMapping?.[toColId]]
          ?.val || 'Select Value';
      return false;
    } else if (value?.defaultValConstantMapOptions?.[toColId]) {
      const constVal =
        MAPPING_DEFAULT_VALUES_DETAILS?.[
          value?.defaultValConstantMap?.[toColId]
        ]?.val || '';
      additionalSourceMapTitle =
        constVal.concat(
          ' ',
          docsData?.[key]?.headerDataAsObj?.[
            value?.defaultValConstantMapOptions?.[toColId]?.value
          ]?.val,
        ) || 'Select Value';
    }
  });
  if (!isEmpty(additionalSourceMapTitle)) {
    return additionalSourceMapTitle;
  }

  const aggsPickedObj = reduxObject.aggregationColMap?.[toColId];
  //if aggreagtion column is selected
  if (!isEmpty(aggsPickedObj)) {
    return (
      (reduxObject.aggregationMap?.columns ?? []).find(
        ({renderId}) => aggsPickedObj.colId === renderId,
      )?.val ??
      reduxObject.aggregationColMap[toColId].title ??
      ''
    );
  }

  const endpointResponsePickedObj = reduxObject.endpointColMap?.[toColId];
  //if aggreagtion column is selected
  if (!isEmpty(endpointResponsePickedObj)) {
    return endpointResponsePickedObj.title ?? '';
  }

  return 'Select Value';
};

const getDefaultValuesForFieldType = (
  colObject,
  options = {
    onlyCheckValues: false,
    allowDateTimeCustomValues: false,
    filterCopyMoveDisabled: false,
    triggerType: null,
  },
) => {
  const {columnProperties} = colObject || {};
  const fieldType = getOriginalFieldType(colObject);

  if (
    fieldType === FIELD_TYPE_ID.AUTO_INCREMENT_ID &&
    !columnProperties?.[COLUMN_PROPERTY_KEYS.GENERATE_MANUALLY]
  ) {
    return [];
  }

  const DEFAULT_VALUES_CONST =
    options?.triggerType === MESSAGE_AUTOMATION.ON_USER_APP_ACCESS
      ? AUTOMATIONS_APP_BASED_DEFAULT_VALUES
      : options?.allowDateTimeCustomValues
      ? pick(AUTOMATION_DEFAULT_VALUES_FOR_FIELD_TYPE, [
          FIELD_TYPE_ID.DATE,
          FIELD_TYPE_ID.TIME,
          FIELD_TYPE_ID.ASSIGN_TASK,
          FIELD_TYPE_ID.DATE_TIME,
        ])
      : AUTOMATION_DEFAULT_VALUES_FOR_FIELD_TYPE;

  let responseArray = DEFAULT_VALUES_CONST[fieldType] ?? [];

  if (options?.onlyCheckValues) {
    responseArray = responseArray.filter(({id}) => {
      return !NON_CHECK_DEFAULT_VALUES.includes(id);
    });
  }

  if (options?.filterCopyMoveDisabled) {
    responseArray = responseArray.filter(({id}) => {
      return !COPY_MOVE_DISABLED_IDS.includes(id);
    });
  }

  return responseArray.map((item) => {
    if (fieldType === FIELD_TYPE_ID.DATE) {
      const dateFormat =
        colObject?.dateFormat in DATE_FORMATS
          ? colObject.dateFormat
          : DATE_FORMATS.DATE;
      return {
        ...item,
        val: `Current ${capitalize(dateFormat)}`,
      };
    }

    if (fieldType === FIELD_TYPE_ID.TIME) {
      return {
        ...item,
        val: 'Current Time',
      };
    }

    if (fieldType === FIELD_TYPE_ID.DATE_TIME) {
      return {
        ...item,
        val: 'Current Date Time',
      };
    }
    return item;
  });
};

const getFieldsForCustomCondtions = (headerData = [], extra = {}) => {
  return (headerData || []).filter(
    (col) =>
      !DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS_V2.includes(
        getOriginalFieldType(col),
      ),
  );
};

/**
 * Function to get keys for mapping
 * @param {object} newAuto
 * @param {object | null} newAuto.headerMapping
 * @param {object | null} newAuto.defaultValMap
 * @param {object | null} newAuto.defaultValConstantMap
 * @returns {object} Default Values Array.
 */
const getAutomationCopyMoveSetMappingFields = (newAuto, defaultValue = {}) => {
  const pickWithDefault = (obj, keysToPick) => {
    return keysToPick.reduce((acc, key) => {
      if (obj?.[key]) {
        acc[key] = obj[key];
      } else {
        acc[key] = defaultValue;
      }
      return acc;
    }, {});
  };

  return pickWithDefault(
    newAuto,
    Object.keys(COPY_MOVE_SET_EMPTY_MAP),
    defaultValue,
  );
};

const checkIfAutomationCopyMoveSetMappingFieldsMapped = (newAuto) => {
  const commonCondition = Object.keys(COPY_MOVE_SET_EMPTY_MAP).some((key) => {
    return !isEmpty(newAuto?.[key]);
  });
  const additionalDataSourceCondition = !isEmpty(newAuto?.additionalDataSource)
    ? Object.keys(newAuto?.additionalDataSource).some((docId) => {
        const sourceDoc = newAuto.additionalDataSource[docId];
        return Object.keys(COPY_MOVE_SET_EMPTY_MAP).some((key) => {
          return !isEmpty(sourceDoc?.[key]);
        });
      })
    : false;
  return commonCondition || additionalDataSourceCondition;
};

const getDefaultValueObject = (colId, defaultValMap) => {
  if (has(defaultValMap?.[colId], 'parentMeta')) {
    const parentDocId = defaultValMap?.[colId]?.parentMeta?.docId;
    const parentMeta = {};
    const rowValues = {};
    Object.keys(defaultValMap).forEach((id) => {
      if (defaultValMap[id]?.parentMeta?.docId === parentDocId) {
        const rowVal = omit(defaultValMap[id], ['parentMeta']);
        const parentMetaObj = defaultValMap[id]?.parentMeta;
        if (parentMetaObj) {
          parentMeta[id] = parentMetaObj;
          rowValues[id] = rowVal;
        }
      }
    });

    if (isEmpty(parentMeta) || isEmpty(rowValues)) {
      return null;
    }

    return {parentMeta, rowValues};
  }

  return defaultValMap?.[colId] ?? null;
};

const getAllTableColumnIDsForGivenColumn = (colId, headerData) => {
  const colData = headerData.find((item) => item.id === colId);
  const docId = findKey(colData?.linkedMeta);
  if (!docId) {
    return [];
  }

  return headerData
    .filter((item) => !isEmpty(item?.linkedMeta?.[docId]))
    .map((item) => item.id);
};

const getUpdatedColMappingsForTableColumns = (colId, headerData, newAuto) => {
  const {
    headerMapping,
    defaultValConstantMap,
    defaultValMap,
    defaultValConstantMapOptions,
    mappingVersion,
  } = newAuto ?? {};
  const colIds = getAllTableColumnIDsForGivenColumn(colId, headerData);

  const updatedHeaderMapping =
    mappingVersion === MAPPING_VERSION
      ? omit(headerMapping, colIds)
      : invert(omit(invert(headerMapping), colIds));
  const updatedDefaultValConstantMap = omit(defaultValConstantMap, colIds);
  const updatedDefaultValMap = omit(defaultValMap, colIds);
  const updatedDefaultValConstantMapOptions = omit(
    defaultValConstantMapOptions,
    colIds,
  );

  return {
    headerMapping: updatedHeaderMapping,
    defaultValConstantMap: updatedDefaultValConstantMap,
    defaultValMap: updatedDefaultValMap,
    defaultValConstantMapOptions: updatedDefaultValConstantMapOptions,
  };
};

const hasAnyTableColumnInMapping = (colId, headerData, newAuto) => {
  const {
    headerMapping,
    defaultValConstantMap,
    defaultValMap,
    defaultValConstantMapOptions,
  } = newAuto ?? {};
  const colIds = getAllTableColumnIDsForGivenColumn(colId, headerData);

  const hasHeaderMapping = colIds.some((id) => invert(headerMapping)?.[id]);
  const hasDefaultValConstantMap = colIds.some(
    (id) => defaultValConstantMap?.[id],
  );
  const hasDefaultValMap = colIds.some((id) => defaultValMap?.[id]);
  const hasDefaultValConstantMapOptions = colIds.some(
    (id) => defaultValConstantMapOptions?.[id],
  );

  return (
    hasHeaderMapping ||
    hasDefaultValConstantMap ||
    hasDefaultValMap ||
    hasDefaultValConstantMapOptions
  );
};

const getFieldsForSetValueLocalState = (operationFileData, newAuto) => {
  const headerData = Object.values(
    operationFileData?.fileData?.headerData ?? {},
  );

  if (!headerData.length) {
    return [];
  }

  const {
    headerMapping,
    defaultValMap,
    defaultValConstantMap,
    additionalDataSource,
    aggregationColMap,
    endpointColMap,
  } = newAuto ?? {};

  const additionalDataSourceColIds = !isEmpty(additionalDataSource)
    ? Object.keys(additionalDataSource).reduce((acc, docId) => {
        const {headerMapping, defaultValConstantMap} =
          additionalDataSource[docId];
        if (!isEmpty(headerMapping)) {
          acc.push(...Object.keys(headerMapping));
        }
        if (!isEmpty(defaultValConstantMap)) {
          acc.push(...Object.keys(defaultValConstantMap));
        }
        return acc;
      }, [])
    : [];

  const toColIds = [
    // Using values as "Target" columns are in "values" of headerMapping.
    ...(newAuto?.mappingVersion === MAPPING_VERSION
      ? Object.keys(headerMapping || {})
      : Object.values(headerMapping || {})),

    ...Object.keys(defaultValMap || {}),
    ...Object.keys(defaultValConstantMap || {}),
    ...Object.keys(aggregationColMap || {}),
    ...Object.keys(endpointColMap || {}),
    ...additionalDataSourceColIds,
  ];

  return headerData.filter((col) => toColIds.includes(col?.id)).slice();
};

const checkIfRuntimeAutomationConfigAvailable = (
  automationConfigData = {},
  automationIds = [],
  docsData = {},
  onlyEditorOption = false,
) => {
  const runTimeConfig = {};
  let hasRunTimeConfigured = false;
  automationIds?.forEach?.((automationId) => {
    const automationConfig = automationConfigData?.[automationId];
    const idPasteTo = automationConfig?.idPasteTo;
    forOwn(automationConfig?.defaultValConstantMap || {}, (value, key) => {
      if (value === AUTOMATION_COLUMN_VALUE_TYPES.SET_VALUE_ON_RUNTIME) {
        if (
          !automationConfigData?.[automationId]?.isEnabled ||
          !has(docsData, idPasteTo)
        ) {
          return;
        }
        hasRunTimeConfigured = true;
        runTimeConfig[automationId] = runTimeConfig[automationId] || {};
        runTimeConfig[automationId][idPasteTo] =
          runTimeConfig[automationId][idPasteTo] || {};
        runTimeConfig[automationId][idPasteTo][key] = true;
      }
    });
  });
  if (onlyEditorOption) {
    return hasRunTimeConfigured;
  }
  return {openEditor: hasRunTimeConfigured, runTimeConfig};
};

export const automationUtils = {
  getUserTypeVal,
  getAvailableColumns,
  handleAddonCallBack,
  getAutomationTableState,
  getUserDefinedTemplateDisplayMessage,
  sortTemplatesArray,
  getTemplateFilterArray,
  formatColIdMapFirestore,
  getAutomationCurrentTemplateId,
  getAutomationUpdatedTemplateId,
  getCurrentAutomationColIdMappingUtils,
  getCurrentPhoneColId,
  getNotificationTemplate,
  getNotificationTemplateTitle,
  getCellStatusNotifTemplateId,
  getTriggerCurrentStatus,
  getTriggerSelectedStatus,
  getTriggerUpdatedNotificationTemplateID,
  getUpdatedAutomationColIdMapping,
  isAddUpdateButtonAvailable,
  firestoreAutoId,
  getCurrentNotificationTemplateId,
  getUpdatedNotificationTemplateId,
  getColumnScreenFlatlistData,
  templateMappingContinueEnabled,
  templateNotificationKeys,
  checkIsTriggerAutomation,
  getColumnRegex,
  isAutomationDataAvailable,
  getAutomationColumnsWithPrevRowRef,
  getAutomationDisplayData,
  getAutomationDocumentMeta,
  miniAppAutomationMapToList,
  checkAllSetValueConditionsFilled,
  getChooseFieldsForSetValue,
  filterAvailableAutomationsForTable,
  getFieldsForSetConditionMapping,
  getTitleForValueMapping,
  getDefaultValuesForFieldType,
  getFieldForValueMapping,
  formulaToAny,
  getFieldsForCustomCondtions,
  getDefaultValueObject,
  getAllTableColumnIDsForGivenColumn,
  getUpdatedColMappingsForTableColumns,
  hasAnyTableColumnInMapping,
  getAutomationCopyMoveSetMappingFields,
  checkIfAutomationCopyMoveSetMappingFieldsMapped,
  getFieldsForSetValueLocalState,
  templateMappingContinueEnabledV2,
  setConditionComponentValueDisplayText,
  checkIfRuntimeAutomationConfigAvailable,
};
